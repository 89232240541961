import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import { useAuth } from './AuthContext'; // Import the useAuth hook
import { loadStripe } from '@stripe/stripe-js';
import './subscribe.css';

const Subscribe = () => {
  const { currentUser } = useAuth(); // Retrieve the current user state
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showAdditionalMessage, setShowAdditionalMessage] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  

  const refreshAuthToken = async () => {
    try {
      if (currentUser) {
        const token = await currentUser.getIdToken(true);  // Force refresh token
        localStorage.setItem('authToken', token);
        return token;
      }
      return null;
    } catch (error) {
      console.error('Error refreshing auth token:', error);
      return null;
    }
  };


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function openLinkInNewTab() {
    window.open('https://linktr.ee/udinbv', '_blank');
  }


  const handleLogout = () => {
    signOut(auth).then(() => {
      // User is now signed out
      localStorage.removeItem('authToken');
      navigate('/login'); // Navigate to login after logout
    }).catch((error) => {
      console.error("Logout failed: ", error);
    });
  };

  const handleSubscribe = async () => {
    if (!currentUser) {
      alert('You must be logged in to subscribe.');
      navigate('/login');
      return;
    }

    if (!isChecked) {
      alert('Please agree to the terms of service to proceed.');
      return;
    }

    setIsLoading(true); // Show the overlay

    const timeout = setTimeout(() => {
      setShowAdditionalMessage(true);
    }, 10000);

    try {
      const authToken = await refreshAuthToken();
      if (!authToken) {
        console.error('Unable to refresh authToken');
        setIsLoading(false);
        clearTimeout(timeout); 
        return;
      }

      const response = await fetch('/.netlify/functions/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ uid: currentUser.uid }) // Send user ID to the serverless function, currentUser comes from your auth state
      });

      const { sessionId } = await response.json();

      // Redirect to Stripe Checkout
      const stripe = await loadStripe('pk_live_51Oa0uYFnbaOJ2kfyyqHRkEYjsVjB4PRRKB0ZrxRmfBcMOUXxQzVTkGSkkkSfGiEEvTVjn4iU59pwg8H37prN4wa700rfwU8vb4');
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setIsLoading(false);
      clearTimeout(timeout); 
    }
  };



  return (
    <div className="subscribe">
    
      <div className="subscribeSecContainer">

        <div className="logo" style={{ scale: '75%' }}></div>

      </div>

      <div className="subscribeMainContainer">

        <div className="subscribeContainer" style={{ opacity: '0%' }}>

        </div>

        <div className="subscribeContainer">

          <h1 className='subscribeTitle' style={{ marginBottom: '5px', marginLeft: '5px' }}>Subscribe</h1>

          <div style={{ display: 'inline-block', marginTop: '13px', marginBottom: '13px' }} className="horizontal-line3"></div>

            <h2 className='breadTitle' style={{ marginBottom: '0px', marginLeft: '5px' }}>Optic Early Access</h2>


            <p className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}>Get early access to the Optic tool and support the development. Gives you access to all the Pro features and updates with the latest builds.</p>

            <div style={{marginLeft: '-5px'}}>

              <div className="customCheckboxContainer">

                <label className="checkboxLabel">

                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="checkboxInput"
                  />
                  <span className="fakeCheckbox"></span>
                </label>

                <span className='breadText' style={{ marginBottom: '0px', marginLeft: '-5px' }}>I agree to the Optic <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" className="breadLink">Terms of Service</a>.</span>

              </div>
            </div>

            <button className='subscribeButton' onClick={handleSubscribe}>€ 49,95 / month</button>

            {isLoading && (
              <div className="overlaySubscription">
                <div className="overlaySubscriptionMessage">
                  Creating checkout...
                  {showAdditionalMessage && (
                    <div className="overlaySubscriptionAddMessage">
                      If the page doesn't automatically redirect you, try to clear your cookies for this page and try again.
                    </div>
                  )}
                </div>
              </div>
            )}

            <span className='breadTitle' style={{ marginBottom: '0px', marginTop: '10px', marginLeft: '10px', fontSize: '12px' }}>Features</span>

            <span className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}>Unlimited Generations</span>

            <span className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}>Shape Control & Inspiration Image</span>

            <span className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}>Refiner & 4K Exports</span>

            <span className='breadText' style={{ marginBottom: '5px', marginLeft: '10px' }}>AI Assistant</span>
            
            <p className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}><span style={{ color: '#FFFFFF' }}>Note</span> This an early access product. This means that there will be bugs and issues along the way. You should only proceed if you are comfortable with this.</p>

            <p className='breadText' style={{ marginBottom: '10px', marginLeft: '10px' }}>We are constantly updating and adding new capabilities to the tool. The Early Access is a fantastic way to support the project and we are grateful for anyone that does so!</p>

        </div>

        <div className="subscribeContainer">

          <h1 className='subscribeTitle' style={{ marginBottom: '5px', marginLeft: '5px' }}>Corporate</h1>

          <div style={{ display: 'inline-block', marginTop: '13px', marginBottom: '13px' }} className="horizontal-line3"></div>

            <h2 className='breadTitle' style={{ marginBottom: '0px', marginLeft: '5px' }}>Optic Enterprise</h2>




            <p className='breadText' style={{ marginBottom: '34px', marginLeft: '10px' }}>If you are a company and are looking for custom configurations of Optic for your business. Let's get in contact!</p>

            <button className='corporateButton' onClick={openLinkInNewTab}>Contact</button>

            <span className='breadTitle' style={{ marginBottom: '0px', marginTop: '10px', marginLeft: '10px', fontSize: '12px' }}>Additional Features</span>

            <span className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}>Local Set Up</span>

            <span className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}>Custom Fine-tuning</span>

            <span className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}>Private Instances</span>

            <span className='breadText' style={{ marginBottom: '5px', marginLeft: '10px' }}>Custom Model Configs</span>
            
            <p className='breadText' style={{ marginBottom: '0px', marginLeft: '10px' }}><span style={{ color: '#FFFFFF' }}>Note</span> This an early access product. This means that there will be bugs and issues along the way. You should only proceed if you are comfortable with this.</p>

            <p className='breadText' style={{ marginBottom: '10px', marginLeft: '10px' }}>We are constantly updating and adding new capabilities to the tool. The Early Access is a fantastic way to support the project and we are grateful for anyone that does so!</p>



        </div>

      </div>

      <div className="subscribeSecContainer">

      <span className= 'logOutText' style={{ fontSize: '12px' }} onClick={handleLogout}>Log Out</span>

      </div>

    </div>
  );
};

export default Subscribe;