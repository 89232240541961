// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeOL-6Q0L_FHq6loiLuNzSlBjkiqUNyC4",
  authDomain: "optic-f714a.firebaseapp.com",
  projectId: "optic-f714a",
  storageBucket: "optic-f714a.appspot.com",
  messagingSenderId: "31604573818",
  appId: "1:31604573818:web:f9d03cb53c21885dc01dc3",
  measurementId: "G-9BG06QJHBM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { app, analytics, auth, googleProvider, facebookProvider, microsoftProvider, db, storage };