import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const RequireAuth = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      checkSubscription(currentUser.uid).then(subscribed => {
        setIsSubscribed(subscribed);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  const checkSubscription = async (uid) => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error('No authToken found');
      return;
    }
    // POST request to Netlify function that checks the user's subscription status
    try {
      const response = await fetch('/.netlify/functions/get-subscription-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ uid }),
      });
      
      if (!response.ok) {
        // Handle errors here, such as by throwing an exception or returning `false`
        return false;
      }

      const data = await response.json();
      return data.isSubscribed;

    } catch (error) {
      console.error('Error checking subscription status:', error);
      return false;
    }
  };

  // Render a loading state until we've finished checking the user's subscription status
  if (isLoading) {
    return <div>Loading...</div>; // Replace with your own loading indicator
  }

  // Redirect the user to the login page if they are not signed in
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Redirect the user to the subscribe page if they do not have an active subscription
  if (!isSubscribed) {
    return <Navigate to="/subscribe" state={{ from: location }} replace />;
  }

  // The user is authenticated and has an active subscription, so allow them to see the children components
  return children;
};

export default RequireAuth;