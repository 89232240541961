import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './login.css';
import { app, auth, googleProvider, facebookProvider, microsoftProvider } from './firebase-config';
import { signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { getFirestore, doc, setDoc } from "firebase/firestore";

const Login = ({ onAuthComplete }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);

  // Fetch the route to navigate back to from the location state, or default to "/"
  const from = location.state?.from?.pathname || '/';

  const handleSocialLogin = async (provider) => {
    try {
      // Try to sign in using the popup method
      const result = await signInWithPopup(auth, provider);
      
      const user = result.user;
      const token = await user.getIdToken();

      if (token) {
        localStorage.setItem('authToken', token);
        if (onAuthComplete) {
          onAuthComplete(token);
        }
      }
      
      // Save user to Firestore
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        lastLogin: new Date()
      }, { merge: true });

      // Redirect to the desired page
      navigate(from, { replace: true });
    } catch (error) {
      if (error.code === 'auth/cancelled-popup-request' || error.code === 'auth/popup-blocked' || error.code === 'auth/popup-closed-by-user') {
        // If popup is blocked or canceled, fallback to redirect method
        signInWithRedirect(auth, provider);
      } else {
        alert(error.message);
      }
    }
  };

  return (
    <div className="Login">
    
      <div className="secondaryContainer">

        <div className="logo" style={{ scale: '75%' }}>

        </div>

      </div>


      <div className="centerContainer">

        <div className="loginContainer">

            <h1 className='loginTitle' style={{ marginBottom: '5px', marginLeft: '5px' }}>Login</h1>

            <div style={{ display: 'inline-block', marginTop: '13px', marginBottom: '23px' }} className="horizontal-line2"></div>
          
            {/* <input
                className='loginInputContainer'
                type="text"
                placeholder="Email address"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                className='loginInputContainer'
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button className='loginButton' onClick={handleLogin}>Continue</button>

            <div style={{ textAlign: 'center', margin: '10px' }}>

                <span className='loginText'>OR</span>

            </div> */}

            <button className='googleLoginButton' onClick={() => handleSocialLogin(googleProvider)}>

              <div style={{ marginLeft: '5px', marginTop: '0px', marginBottom: '10px' }}>

                <div style={{ marginTop: '-1px', marginBottom: '14px' }}>

                  <span className='googleText'>Continue with Google</span>
                
                </div>

                <div className="googleIcon" style={{ marginLeft: '25px', marginTop: '-35px', marginBottom: '-15px' }}></div>

              </div>

            </button>

                {/* <div className='socialLoginContainer'> 

                    <div className="socialLoginButton"  onClick={() => handleSocialLogin(googleProvider)}>

                        <div className="googleIcon" style={{ marginLeft: '5.5px', marginTop: '2.5px' }}></div>

                    </div>

                    <div className="socialLoginButton" onClick={() => handleSocialLogin(facebookProvider)}>

                    <div className="facebookIcon" style={{ marginLeft: '5.5px', marginTop: '2.5px' }}></div>

                    </div>

                    <div className="socialLoginButton" onClick={() => handleSocialLogin(microsoftProvider)}>

                    <div className="microsoftIcon" style={{ marginLeft: '5.5px', marginTop: '2.5px' }}></div>

                    </div>  

                </div> */}

            {/* <div style={{ textAlign: 'center', margin: '8px' }}>

                <span className='loginText'>Don't have an account?<a href='/signup' className='loginText' style={{color: '#FFFFFF'}}>Sign up</a></span>

            </div> */}

        </div>

      </div>

      <div className="secondaryContainer">

      </div>

    </div>
  );
};

export default Login;