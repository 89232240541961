import React from 'react';
import './Tooltip.css';

const Tooltip = ({ message, children, position='top', delay = 500, distance = 10, style={}, className='' }) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState(null);

    const handleMouseEnter = () => {
        const id = setTimeout(() => {
            setIsVisible(true);
        }, delay);
        setTimeoutId(id);
    };

    const handleMouseLeave = () => {
        clearTimeout(timeoutId);
        setIsVisible(false);
    };

    const getTooltipStyle = () => {
        switch (position) {
            case 'top':
                return { bottom: `calc(100% + ${distance}px)`, left: '50%', transform: 'translateX(-50%)' };
            case 'right':
                return { top: '50%', left: `calc(100% + ${distance}px)`, transform: 'translateY(-50%)' };
            case 'bottom':
                return { top: `calc(100% + ${distance}px)`, left: '50%', transform: 'translateX(-50%)' };
            case 'left':
                return { top: '50%', right: `calc(100% + ${distance}px)`, transform: 'translateY(-50%)' };
            default:
                return {};
        }
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
            style={{ ...style, position: 'relative', display: 'inline-block' }} // Merge passed style with needed styles
            className={className} // Apply any passed className
        >
            {children}
            {
                isVisible && (
                    <div className={`tooltip-bubble`} style={getTooltipStyle()}>
                        <div className="tooltip-message">{message}</div>
                    </div>
                )
            }
        </div>
    );
};

export default Tooltip;