import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Trainer.css'; 
import Tooltip from './Tooltip';
import './App.css';
import { debounce } from 'lodash';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { auth, storage, db } from './firebase-config';
import { collection, doc, setDoc, getDoc, getDocs } from "firebase/firestore";
import imageCompression from 'browser-image-compression';
import Pica from 'pica';

const Trainer = ({ isOpen, closeTrainer, onTrainingCompleted, loras }) => {
    const [images, setImages] = useState([]);
    const [ghostCount, setGhostCount] = useState(0);
    const [isDragging, setIsDragging] = useState(false); 
    const [project_name, setProject_name] = useState('');
    const [trainingStage, setTrainingStage] = useState(null);
    const [trainingCompleted, setTrainingCompleted] = useState(false);

    const toggleTrainMode = [
      { id: '1', name: 'General'},
      { id: '2', name: 'Biased'},
    ];
    const [activeTrainButton, setActiveTrainButton] = useState(toggleTrainMode[0]);
  
  
    const toggleTrainActive = (id) => {
      const newActiveButton = toggleTrainMode.find(button => button.id === id);
      setActiveTrainButton(newActiveButton);
    };
    const isTrainActive = (button) => button.id === activeTrainButton.id;

    const fileInputRef = useRef(null);

    
    const checkTrainingStatus = useCallback(async (user_id, dataset_id) => {
      const docRef = doc(db, 'userSettings', user_id, 'loras', dataset_id);
    
      // Function to check the status in Firestore
      const checkStatus = async () => {
        try {
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data.status === 'completed' && !trainingCompleted) {
              console.log("Training completed.");
              setTrainingStage("completed");
              setTrainingCompleted(true);
              onTrainingCompleted();


            } else if (data.status === 'failed') {
              console.log("Training failed.");
              setTrainingStage(null);
              setTrainingCompleted(false);
              onTrainingCompleted();
            } else {
              throw new Error("Training in progress.");
            }
          } else {
            throw new Error("No status document found.");
          }
        } catch (error) {
          setTimeout(checkStatus, 60000); // Check again after 1 minute
        }
      };
    
      checkStatus();
    }, [onTrainingCompleted, trainingCompleted]);

    useEffect(() => {
      const user_id = auth.currentUser ? auth.currentUser.uid : null; // Ensure auth.currentUser is available
      if (!user_id) return;
  
      const checkOngoingTraining = async () => {
          const lorasCollectionRef = collection(db, 'userSettings', user_id, 'loras');
          const lorasSnapshot = await getDocs(lorasCollectionRef);
  
          lorasSnapshot.forEach(doc => {
              if (doc.exists() && doc.data().status === 'in_progress') {
                  setTrainingStage("training");
                  checkTrainingStatus(user_id, doc.id);
              }
          });
      };
  
      checkOngoingTraining();
  }, [checkTrainingStatus]);

    useEffect(() => {
        const handleResize = debounce(() => {
          // Check if the element is present
          const element = document.querySelector('.imageGallery');
          if (element) {
            const containerWidth = element.offsetWidth;
            const itemWidth = 140; // Adjust depending on your actual thumbnail width + margins/gaps
            const itemsPerRow = Math.floor(containerWidth / itemWidth);
            const remainder = images.length % itemsPerRow;
            const newGhostCount = remainder === 0 ? 0 : itemsPerRow - remainder;
      
            setGhostCount(newGhostCount);
          }
        }, 100);
      
        // Initialize and set up the resize event listener
        window.addEventListener('resize', handleResize);
        handleResize(); // Call immediately to set initial state
      
        // Cleanup on component unmount
        return () => window.removeEventListener('resize', handleResize);
      }, [images.length]);
  
  
    if (!isOpen) return null;

    
    const handleImageChange = (e) => {
      const files = Array.from(e.target.files);
      const acceptedImages = [];
      let invalidImageFound = false; // Flag to track if any invalid images are found
      const acceptedTypes = ['image/jpeg', 'image/png', 'image/webp']; // Define acceptable MIME types
      
      const processImage = (file, callback) => {
          const img = new Image();
          const url = URL.createObjectURL(file);
          
          img.onload = () => {
              if (img.width < 512 || img.height < 512) {
                  invalidImageFound = true; // Set the flag to true if the image is invalid
                  URL.revokeObjectURL(url); // Clean up URL.createObjectURL
              } else {
                  acceptedImages.push({
                      url: url,
                      name: file.name,
                  });
              }
              callback(); // Call the callback function to indicate processing is complete
          };
          img.src = url; // Starts loading the image
      };
  
      const allFilesProcessed = () => {
          if (invalidImageFound) {
              alert("Some images do not meet the minimum size requirement of 512px in both dimensions and will be skipped.");
          }
          if (acceptedImages.length > 0) {
              setImages(prevImages => [...prevImages, ...acceptedImages]);
          }
      };
  
      let processedCount = 0; // Count of processed files
      files.forEach(file => {
        if (acceptedTypes.includes(file.type)) {
          processImage(file, () => {
              processedCount++;
              if (processedCount === files.length) {
                  // If all files have been processed, invoke the final step
                  allFilesProcessed();
              }
          });
        } else {
          invalidImageFound = true; // Set the flag to true if the file type is invalid
          processedCount++;
          if (processedCount === files.length) {
              // If all files have been processed, invoke the final step
              allFilesProcessed();
          }
      }
      });
  };
  
    const handleImageDelete = (indexToRemove) => {
        setImages(images.filter((_, index) => index !== indexToRemove));
        // Ghost count will be recalculated because it depends on images.length
      };
  
      const renderImages = () => {
        return (
          <>
            {images.map((image, index) => (
              <div key={index} className="trainerThumbnail">
                <img src={image.url} alt={image.name} />
                <div className="trainerThumbnailOverlay" onClick={() => handleImageDelete(index)}>Remove</div>
              </div>
            ))}
            {Array.from({ length: ghostCount }).map((_, index) => (
              <div key={`ghost-${index}`} className="trainerThumbnailGhost"></div>
            ))}
          </>
        );
      };

    const handleDragEnter = e => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragOver = e => {
        e.preventDefault(); // Necessary to allow drop
    };

    const handleDragLeave = e => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = e => {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files);
      if (images.length + files.length > 50) {
          alert(`Adding these files would exceed the maximum of 50 images.`);
          setIsDragging(false);
          return;
      }
      handleImageChange({ target: { files: files } });
      setIsDragging(false);
  };

  const resizeImage = async (file, maxDimension) => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(file);

    await new Promise((resolve) => {
        img.onload = resolve;
    });

    const width = img.width;
    const height = img.height;
    const outputCanvas = document.createElement('canvas');

    if (width > maxDimension || height > maxDimension) {
        if (width > height) {
            outputCanvas.width = maxDimension;
            outputCanvas.height = (height / width) * maxDimension;
        } else {
            outputCanvas.width = (width / height) * maxDimension;
            outputCanvas.height = maxDimension;
        }
    } else {
        outputCanvas.width = width;
        outputCanvas.height = height;
    }

    const pica = Pica();
    await pica.resize(img, outputCanvas);

    return new Promise((resolve) => {
        outputCanvas.toBlob((blob) => {
            resolve(blob);
        }, 'image/jpeg');
    });
};

const handleTrainingButton = async () => {
  if (images.length < 10) {
      alert('You need at least 10 images to start training.');
      return;
  }

  const trimmedProjectName = project_name.trim();

  if (trimmedProjectName === "") {
      alert('Please provide a name for the training.');
      return;
  }

  setTrainingStage("submitting");

  const user_id = auth.currentUser.uid; // Assuming you have access to the authenticated user's ID
  const dataset_id = uuidv4();
  let errorLog = `Error Log for dataset_id: ${dataset_id}\n\n`;

  const MAX_SIZE_MB = 10;
  const MAX_DIMENSION = 4096;

  const imageUploadPromises = images.map(async (image, index) => {
      try {
          const response = await fetch(image.url);
          const blob = await response.blob();

          // Resize image if necessary
          const resizedBlob = await resizeImage(blob, MAX_DIMENSION);

          // Compress image if necessary
          const compressedBlob = await imageCompression(resizedBlob, {
              maxSizeMB: MAX_SIZE_MB,
              useWebWorker: true,
              maxWidthOrHeight: MAX_DIMENSION,
              onProgress: console.log,
          });

          // Upload to Firebase Storage
          const imageRef = ref(storage, `datasets/${user_id}/${dataset_id}/${index}.jpg`);
          await uploadBytes(imageRef, compressedBlob);

          return await getDownloadURL(imageRef);
      } catch (error) {
          console.error(`Error processing image ${index}:`, error);
          errorLog += `Error processing image ${index}: ${error.message}\n`;
          return null;
      }
  });

  try {
      const imageUrls = await Promise.all(imageUploadPromises);
      const validImageUrls = imageUrls.filter(url => url !== null);

      if (validImageUrls.length < 10) {
          alert('Not enough images were successfully processed and uploaded. Please check the images and try again.');
          setTrainingStage(null);
          return;
      }

      const response = await fetch('/.netlify/functions/handle-training-background', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id, dataset_id, imageUrls: validImageUrls, project_name, trainingMode: activeTrainButton.name }),
      });

      if (response.ok) {
          console.log('Training initiation acknowledged');

          setTrainingStage("training");

          await setDoc(doc(collection(db, 'userSettings', user_id, 'loras'), dataset_id), {
              status: 'in_progress',
              project_name: project_name,
              training_mode: activeTrainButton.name,
          });

          checkTrainingStatus(user_id, dataset_id); // Start checking training status
      } else {
          throw new Error('Failed to initiate training process');
      }
  } catch (error) {
      console.error('Error sending request to start training:', error);
      alert('Error initiating the training process. Please check your connection and try again later.');

      // Upload the error log file including the final error message
      errorLog += `Error initiating training process: ${error.message}\n`;
      const logBlob = new Blob([errorLog], { type: 'text/plain' });
      const logRef = ref(storage, `datasets/${user_id}/${dataset_id}/error_log.txt`);
      await uploadBytes(logRef, logBlob);

      // Reset training stage on error
      setTrainingStage(null);
  }
};
  
    return (
      <div className="overlay">
        <div className="menu">
          <div className="trainerTitleContainer">
            <span style={{ fontSize: '22px', marginLeft: '32px' }} className='boldText'>Lenses</span>
            <button className="closeButton" onClick={closeTrainer}>
              <div className="closeIcon" style={{ marginLeft: '2px' }}></div>
            </button>
          </div>
          <div style={{ display: 'inline-block', marginTop: '1px' }} className="horizontal-line-trainer"></div>
          <div className="trainerContent">

          <div className="trainerContentContainer"
               onDragOver={handleDragOver}
               onDragEnter={handleDragEnter}
               onDragLeave={handleDragLeave}
               onDrop={handleDrop}
               style={{ backgroundColor: isDragging ? '#333636' : 'initial' }}
          >
            {/* Input field and other components */}
            <input 
                type="file" 
                multiple 
                onChange={handleImageChange} 
                accept="image/jpeg,image/png,image/webp"
                ref={fileInputRef} 
                style={{ display: 'none' }}
            />

            <div className="trainerTitleContainer">
                <span style={{ fontSize: '16px', marginLeft: '52px', marginTop: '32px' }} className='boldText'>Dataset</span>
            </div>


            {trainingStage === "submitting" ? (

              <div className="trainingLoadingScreen">
                  <span style={{ fontSize: '54px' }}>Submitting...</span>
                  <span style={{ fontSize: '12px' }}>Please do not leave the page until submission is complete.</span>
              </div>

              ) : trainingStage === "training" && (
              <div className="trainingLoadingScreen">
                  <span style={{ fontSize: '54px' }}>Training In Progress...</span>
                  <span style={{ fontSize: '12px' }}>Training a lens takes roughly 20 minutes.</span>
                  <span style={{ fontSize: '12px' }}>Once finished, you can use it in the Lenses tab.</span>
              </div>
              )}

              {images.length === 0 && (
                  <label className="uploadButton" 
                  onClick={() => {
                          if (images.length >= 50) {
                              alert('You have reached the limit of 50 images.');
                          } else if (fileInputRef.current) {
                              fileInputRef.current.click();
                          } else {
                              console.error('File input is not available');
                          }
                      }}
                  style={{
                    width: '130px',
                    height: '115px',    
                    position: 'relative',
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                    }}>
                    <div className="uploadIcon" style={{marginTop: '12px'}}></div>
                    <span className="normalText" style={{fontSize: '12px', marginTop: '-40px', opacity: '35%'}}>Upload images</span>
                  </label>
              )}
              <div className="imageGallery">
                {renderImages()}
              </div>
            </div>
            <div style={{ display: 'inline-block' }} className="vertical-line-trainer"></div>
            <div className="trainerSettingsContainer">

                <div className="trainerTitleContainer">
                    <span style={{ fontSize: '16px', marginLeft: '22px', marginTop: '32px' }} className='boldText'>Setup</span>
                </div>

                <div className="setupContainer">

                  <input
                      type="text"
                      className="projectNameInput"
                      placeholder="Lens Name"
                      value={project_name}
                      onChange={(e) => setProject_name(e.target.value)}
                      style={{ marginTop:'18px'}}
                      pattern="[]^.()&quot;'\\/+"
                      title="Project name should not contain dots, parentheses, quotes, or slashes, and should not be empty."
                    />

                  <div className="toggleSettingContainer">

                    <Tooltip message="General: The lens will learn normally and will still need the context of the learned concept when generating. If you just want to improve the capabilities of your desired concept, use this. --- Biased: The lens will apply the learned concept with much less regard to the generation context. Use this if you want to the features of the training bleeding into any generation." 
                        position="right" 
                        distance={-30}
                        style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginRight: '15px', color: '#808080' }} 
                        className='normalText'>Training Method</Tooltip>

                    <div className="toggle-container" >
                      {toggleTrainMode.map((button) => (
                      <button
                      key={button.id}
                      className={`toggle-btn${isTrainActive(button) ? ' active' : ''}`}
                      style={{ fontSize: '10px'}}
                      onClick={() => toggleTrainActive(button.id)}
                      >
                      {button.name}
                      </button>
                      ))}
                    </div>

                  </div>

                  <span
                  style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '10px', marginRight: '20px', color: '#808080' }} 
                  className='normalText'>The Lenses feature allows you to teach any new concept, style or object to the model. This enables endless possibilites with what you can create with the tool. </span>

                  <span
                  style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '20px', marginRight: '20px', color: '#808080' }} 
                  className='normalText'>Just provide 10-50 large diverse image set of the concept to teach. The more images the more flexible the learned model.</span>
                  
                  <span
                  style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '20px', marginRight: '20px', color: '#808080' }} 
                  className='normalText'>Minimum image size is 512px in both dimension and the better the quality, the better the model output.</span>
                  
                  <span
                  style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '20px', marginRight: '20px', color: '#808080' }} 
                  className='normalText'>All of the lenses are private to your account and not accessible by anyone else.</span>

                  <span
                  style={{ display: 'inline-block', fontSize: '12px', marginLeft: '20px', marginTop: '20px', marginRight: '20px', color: '#808080' }} 
                  className='normalText'>If you want to learn more, you can checkout this <a href="https://youtu.be/ShCRPxNm2CM" target="_blank" rel="noopener noreferrer" className="breadLink">intro to Lenses video</a>!</span>
                  




                </div>
                
                <div>

                  <div 
                      className={`uploadDatasetButton ${images.length >= 50 ? 'disabledButton' : ''}`}
                      style={{ margin: '30px' }}
                      onClick={() => {
                          if (images.length >= 50) {
                              alert('You have reached the limit of 50 images.');
                          } else if (fileInputRef.current) {
                              fileInputRef.current.click();
                          } else {
                              console.error('File input is not available');
                          }
                      }}
                  >
                    <span className="generateText" style={{ fontSize: '12px' }}>
                        {images.length === 50 ? 
                            <span style={{ opacity: 0.4 }}>Maximum Reached (50/50)</span> :
                            (
                                <>
                                    Upload Images 
                                    {images.length > 0 && <span style={{ opacity: 0.3 }}>({images.length} / 50 max)</span>}
                                </>
                            )
                        }
                    </span>

                  </div>

                  <div
                  className={`generateButton ${(images.length < 10 || trainingStage === 'training' || trainingStage === 'submitting') || loras.length >= 3 ? 'disabledButton' : ''}`}
                  style={{ margin: '30px' }}
                  onClick={trainingStage === null ? handleTrainingButton : null}
                  >
                    <span className="generateText" style={{ fontSize: '12px' }}>
                    {trainingStage === "training" ? 'Training in progress...' :
                    trainingStage === "submitting" ? 'Submitting...' : 
                    (loras.length >= 3 ? 'Start Training (3/3)' : 
                    (images.length < 10 ? 'Minimum 10 images' : 'Start Training'))}
                    </span>

                  </div>

                </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Trainer;