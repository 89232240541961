import React from 'react';
import Login from './login';
import { auth } from './firebase-config';  // Import the auth instance from firebase-config

const BlenderAuth = () => {
  const handleAuthComplete = async () => {
    try {
      const user = auth.currentUser;  // Use auth instead of firebase
      if (user) {
        const idToken = await user.getIdToken();
        const refreshToken = user.refreshToken;  // Obtain the refresh token

        // Send both tokens to local server using fetch
        const response = await fetch('http://localhost:5000', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          mode: 'cors', // Ensure CORS mode is added
          body: JSON.stringify({ idToken, refreshToken }),  // Send both tokens
        });

        // If the response is not OK, throw an error
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Log the server response for debugging
        const responseData = await response.json();

        // Give the UI some time to process then attempt to close the window 
        setTimeout(() => {
          console.log('Attempting to close window...');
          window.close();
          if (!window.closed) {
            const confirmClose = window.open('', '_self');
            confirmClose.close();
          }
        }, 1000);

      }
    } catch (error) {
      console.error('Failed to send token to Blender:', error);
    }
  };

  return <Login onAuthComplete={handleAuthComplete} />;
};

export default BlenderAuth;