import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from './firebase-config';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Subscribe to auth changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user); // Set the user or null
      setLoading(false); // Set loading to false once the user state is established
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  // To make the currentUser available to all components
  const value = {
    currentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};